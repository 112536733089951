import { Component } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { CommonService, LOCAL_STORAGE_KEY } from './services/common.service';
import { LanguageService } from './services/language.service';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  show_discount_code: boolean = false;

  now = new Date();

  constructor(
    private commonService: CommonService,
    private languageService: LanguageService
  ) {
    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    let dark_mode = localStorage.getItem(LOCAL_STORAGE_KEY.DARK_MODE);

    this.initializeDarkTheme((dark_mode != undefined && dark_mode != null) ? (dark_mode == 'false' ? false : true) : true);

    // Initialize the dark theme based on the initial
    // value of the prefers-color-scheme media query
    // this.initializeDarkTheme(true);
    // this.initializeDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addEventListener('change', (mediaQuery) => this.initializeDarkTheme(mediaQuery.matches));


    this.languageService.initLang();
    
    // if (this.now.valueOf() < 1730073600000){
    //   this.show_discount_code = true;
    // }
  }

  // Check/uncheck the toggle and update the theme based on isDark
  initializeDarkTheme(isDark) {
    this.commonService.is_dark_mode = isDark;
    this.commonService.toggleDarkTheme(isDark);
  }


}
