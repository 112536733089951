<ion-header>
  <ion-toolbar>
    <a href="/"> <img slot="start"
        [src]="commonService?.is_dark_mode ? 'assets/icon/header-goswap-dark.png' : 'assets/icon/header-goswap-light.png'"
        alt=""></a>

    <nav slot="end" class="mobile-hide">
      <ion-label (click)="navigationService.navigate('root', 'tesla')">{{'common.unlock_tesla' | translate}}</ion-label>
      <ion-label (click)="navigationService.navigate('root', 'rent-to-buy')">{{'common.rent_to_buy' |
        translate}}</ion-label>
      <ion-label (click)="navigationService.navigate('root', 'rent-with-park')">{{'common.rent_with_park' |
        translate}}</ion-label>
      <!-- <ion-label (click)="navigationService.navigate('root', 'how-to-use')">{{'common.how_to_use' | translate}}</ion-label> -->
      <!-- <a href="http://onelink.to/a9yn2n" target="_blank" rel="noopener noreferrer">
        <ion-label>應用程式</ion-label>
      </a> -->
      <!-- <ion-label>企業方案</ion-label> -->
      <ion-label (click)="navigationService.navigate('root', 'plan')">{{'common.rental_plan' | translate}}</ion-label>
      <ion-label (click)="navigationService.navigate('root', 'faq')">{{'common.help_center' | translate}}</ion-label>
      <ion-label (click)="navigationService.navigate('root', 'blog')">{{'common.blog' | translate}}</ion-label>
      <ion-label class="enterprise-solution-label"
        (click)="navigationService.navigate('root', 'enterprise-solution')">{{'common.enterprise_solution' |
        translate}}</ion-label>
    </nav>
    <div slot="end">
      <ion-toggle [(ngModel)]="themeToggle" (ionChange)="toggleChange($event)" justify="space-between"></ion-toggle>

    </div>
    <div slot="end">
      <button class="lang-menu-btn" mat-icon-button [matMenuTriggerFor]="menu">
        {{translateService?.currentLang == 'zh' ? '中' : 'Eng'}}
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="languageService.setLanguage('zh')" *ngIf="translateService?.currentLang != 'zh'">
          中
        </button>
        <button mat-menu-item (click)="languageService.setLanguage('en')" *ngIf="translateService?.currentLang != 'en'">
          Eng
        </button>
      </mat-menu>
    </div>
    <div slot="end" (click)="menuToggle()" class="menu-btn desktop-hide mobile-show">
      <div class="icon-bar bar-1" [@hamburguerX]="!menuIsOpened ? 'hamburguer' : 'topX'"></div>
      <div class="icon-bar bar-2" [@hamburguerX]="!menuIsOpened ? 'hamburguer' : 'hide'"></div>
      <div class="icon-bar bar-3" [@hamburguerX]="!menuIsOpened ? 'hamburguer' : 'bottomX'"></div>
    </div>
  </ion-toolbar>
  <div class="menu-mobile" [class]="menuIsOpened ? 'menu-mobile-visible' : 'menu-mobile-hidden'">
    <nav>
      <ion-label (click)="navigationService.navigate('root', 'tesla')">{{'common.unlock_tesla' | translate}}</ion-label>
      <ion-label (click)="navigationService.navigate('root', 'rent-to-buy')">{{'common.rent_to_buy' |
        translate}}</ion-label>
      <ion-label (click)="navigationService.navigate('root', 'rent-with-park')">{{'common.rent_with_park' |
        translate}}</ion-label>
      <!-- <ion-label (click)="navigationService.navigate('root', 'how-to-use')">{{'common.how_to_use' | translate}}</ion-label> -->
      <ion-label><a href="http://onelink.to/a9yn2n" target="_blank" rel="noopener noreferrer">{{'common.app' |
          translate}}</a></ion-label>
      <!-- <ion-label>企業方案</ion-label> -->
      <ion-label (click)="navigationService.navigate('root', 'plan')">{{'common.rental_plan' | translate}}</ion-label>
      <ion-label (click)="navigationService.navigate('root', 'faq')">{{'common.help_center' | translate}}</ion-label>
      <ion-label (click)="navigationService.navigate('root', 'blog')">{{'common.blog' | translate}}</ion-label>
      <ion-label class="enterprise-solution-label"
        (click)="navigationService.navigate('root', 'enterprise-solution')">{{'common.enterprise_solution' |
        translate}}</ion-label>
    </nav>
  </div>
</ion-header>