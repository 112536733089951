import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var gtag : any;

export enum EventName {
  rent_to_buy_register = "rent_to_buy_register",
  rent_to_buy_pay = "rent_to_buy_pay",
}

export enum EventCategory {
  Button = "Button",
  Form = "Form",
  Scroll = "Scroll",
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  trackPage(url){
    if (!environment.production) return;

    gtag('event', 'page_view', {
      page_location: url,
    });
  }

  trackEvent(eventName: EventName, eventCategory: string, eventLabel: string, eventValue: string) {
    if (!environment.production) return;

    gtag('event', eventName, {
    // event Type - example: 'SCROLL_TO_TOP_CLICKED'
    'event_category': eventCategory,
    // the label that will show up in the dashboard as the events name
    'event_label': eventLabel,
    // a short description of what happened
    'value': eventValue
    })
  }
}
