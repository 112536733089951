import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppointmentData, CampaignData, CarData, CarViewingData, CompensationPaymentData, CouponData, CustomPageData, DATA_TYPE, DepositData, Engine, EquipmentData, FactoryData, MaintenanceData, OrderData, OrderStatus, ParkingData, PaymentData, PaymentMethod, PlateData, PrerentCar, ProductData, RbbData, RbbEquipmentData, RbbPaymentData, ReturnVehicleReport, SevenCouponData, SystemData, VehicleRentalAgreement, ViolationData } from 'src/schema';
import { ApiPath, ApiService, Response } from './api.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, Platform } from '@ionic/angular';
import { NavigationService } from './navigation.service';


export interface UnavailableBookingDateListResult {
  user_booked_date_list: string[],
  car_booked_date_list: string[],
  car_blocked_date_list: string[]
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  single_parking_charge: number = 980;
  all_parking_charge: number = 1500;

  selected_prerent_car_data: CarData = null;

  rentable_parking_data_list$ = new BehaviorSubject<ParkingData[]>(null);
  all_parking_data_list$ = new BehaviorSubject<ParkingData[]>(null);
  carpark_vacancy_list$ = new BehaviorSubject<any[]>(null);

  prerent_car_list$ = new BehaviorSubject<PrerentCar[]>(null);
  selected_prerent_car$ = new BehaviorSubject<PrerentCar>(null);

  available_factory_data_list$ = new BehaviorSubject<FactoryData[]>(null);

  all_custom_page_data_list$ = new BehaviorSubject<CustomPageData[]>(null);
  
  private_parking_data = [
    {
      "bnb_car_park_id": "",
      "car_id_list": [],
      "cover_img_url": "img_11911_mvVkUrq7.jpg",
      "create_date": "2023-04-03T17:18:36",
      "data_type": "parking_data",
      "default": false,
      "disabled": true,
      "en_address": "NO.34 LEE CHUNG STREET, CHAI WAN",
      "en_category": "",
      "en_name": "Chai Wan - AI Parking Bees",
      "id": 7,
      "is_car_rental_point": false,
      "last_update_datetime": "2023-04-03T17:18:36",
      "latitude": "22.266209215543213",
      "longitude": "114.23625040295954",
      "minimum_booking_days": 1,
      "pick_up_charge": null,
      "price_per_month": 980,
      "quota": 20,
      "rentable": false,
      "show_in_app_filter": false,
      "zh_address": "柴灣利眾街34號寶源利眾商業大廈",
      "zh_category": "柴灣",
      "zh_name": "柴灣 - AI Parking Bees 智能停車場"
    }
  ]
  coming_soon_parking_data = [
    // {
    //   "car_id_list": [],
    //   "cover_img_url": "img_21552_yxQBXzAw.jpg",
    //   "create_date": "2024-02-19T16:50:39",
    //   "data_type": "parking_data",
    //   "default": false,
    //   "disabled": false,
    //   "en_address": "32 Hung To Road, Ngau Tau Kok",
    //   "en_category": "Ngau Tau Kok",
    //   "en_name": "Ngau Tau Kok",
    //   "id": 12,
    //   "is_car_rental_point": false,
    //   "last_update_datetime": "2024-02-19T16:50:39",
    //   "latitude": "22.312387693120176",
    //   "longitude": "114.22004023276024",
    //   "minimum_booking_days": 1,
    //   "pick_up_charge": null,
    //   "price_per_month": 980,
    //   "quota": 0,
    //   "rentable": false,
    //   "show_in_app_filter": false,
    //   "zh_address": "牛頭角鴻圖道32號",
    //   "zh_category": "牛頭角",
    //   "zh_name": "牛頭角"
    // },
    // {
    //   "car_id_list": [],
    //   "cover_img_url": "img_11964_fbtb4qx8.png",
    //   "create_date": "2023-11-08T16:21:13",
    //   "data_type": "parking_data",
    //   "default": false,
    //   "disabled": false,
    //   "en_address": "18 Tai Chung Road, Tsuen Wan",
    //   "en_category": "Tsuen Wan",
    //   "en_name": "AI Parking Bees - Tsuen Wan",
    //   "id": 13,
    //   "is_car_rental_point": false,
    //   "last_update_datetime": "2023-11-08T16:21:13",
    //   "latitude": "",
    //   "longitude": "",
    //   "minimum_booking_days": 1,
    //   "pick_up_charge": null,
    //   "price_per_month": 980,
    //   "quota": 0,
    //   "rentable": false,
    //   "show_in_app_filter": false,
    //   "zh_address": "荃灣大涌道18號國際企業中心三期",
    //   "zh_category": "荃灣",
    //   "zh_name": "荃灣"
    // }
  ];

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private translateService: TranslateService,
    private injector: Injector,
    private platform: Platform,
    private navigationService: NavigationService,
  ) {

  }

  // getAllRentableCarData(refresh_event?, engine?: Engine, prerent?: boolean): Promise<any> {
  //   return new Promise((resolve, reject) => {

  //     let send_data = {
  //       with_not_available_car_data_list: true
  //     }
  //     if (this.booking_start_date$.value) send_data['start_date'] = this.booking_start_date$.value;
  //     if (this.booking_end_date$.value) send_data['end_date'] = this.booking_end_date$.value;
  //     if (this.authService.userData$.value) send_data['user_id'] = this.authService.userData$.value.id;
  //     if (engine) send_data['engine'] = engine;
  //     if (prerent != undefined) send_data['prerent'] = prerent;
  //     this.apiService.postFromServer(ApiPath.get_all_rentable_car_data, send_data).then(async (res: Response) => {
  //       if (res.result == 'success') {
  //         let car_data_list: CarData[] = res.data;
  //         this.rentable_car_data_list$.next(car_data_list);
  //         if (this.selected_parking_id$.value != null && this.booking_start_date$.value && prerent == undefined) {
  //           this.display_rentable_car_data_list$.next(car_data_list.filter((d: CarData) => d.last_return_parking_id == this.selected_parking_id$.value));
  //         }
  //         else {
  //           this.display_rentable_car_data_list$.next(car_data_list);
  //         }
  //         if (refresh_event) {
  //           refresh_event.target.complete();
  //         }

  //         console.log("rentable_car_data_list: ", this.rentable_car_data_list$.value);
  //         resolve(true);
  //       }

  //     }).catch(err => {
  //       console.error(err);
  //       if (refresh_event) {
  //         refresh_event.target.complete();
  //       }
  //       reject(err);
  //     })
  //   });

  // }

  getAllRentableParkingDataList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.postFromServer(ApiPath.get_all_rentable_parking_data_list, null).then(res => {
        // console.log(res.data);
        this.rentable_parking_data_list$.next(res.data);

        resolve(true);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }

  getAllTypeOfParkingDataList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.postFromServer(ApiPath.get_all_parking_data_for_display_board, null).then(res => {
        // console.log(res.data);
        this.rentable_parking_data_list$.next(res.data.filter((p: ParkingData) => p.rentable == true));
        this.all_parking_data_list$.next(res.data);

        resolve(true);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }

  getAvailablePublicFactoryDataList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.postFromServer(ApiPath.get_available_public_factory_data_list, null).then(res => {
        console.log(res.data);
        this.available_factory_data_list$.next(res.data);
        resolve(true);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }
  

  getAllFaqDataList(): Promise<any> {
    return new Promise((resolve, reject) => {
      let send_data = {
      }
      this.apiService.postFromServer(ApiPath.get_all_faq_excluding_disabled, send_data, false).then((res: Response) => {
        resolve(res);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }

  getAllActiveCustomPage(field_list?: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      let send_data = {
        web_visible: true
      }
      if (field_list){
        send_data['field_list'] = field_list
      }
      this.apiService.postFromServer(ApiPath.get_all_active_custom_page, send_data, false).then((res: Response) => {
        this.all_custom_page_data_list$.next(res.data);
        resolve(res);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }

  getCustomPageByShortName(short_name: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let send_data = {
        short_name: short_name,
        web_visible: true
      }
      this.apiService.postFromServer(ApiPath.get_custom_page_by_short_name, send_data, false).then((res: Response) => {
        resolve(res);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }

  getAllRentableCarData(): Promise<Response> {
    return new Promise((resolve, reject) => {
      let send_data = {
        skip_availability_checking: true
      }
      this.apiService.postFromServer(ApiPath.get_all_rentable_car_data, send_data, false).then((res: Response) => {
        resolve(res);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }

  getCarDataById(car_id: number): Promise<Response> {
    return new Promise((resolve, reject) => {
      let send_data = {
        id: car_id
      }
      this.apiService.postFromServer(ApiPath.get_car_data_by_id, send_data, false).then((res: Response) => {
        resolve(res);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }

  getAllAwaitingApplicationRbbData(): Promise<Response> {
    return new Promise((resolve, reject) => {
      let send_data = {
        with_car_data: true
      }
      this.apiService.postFromServer(ApiPath.get_all_awaiting_application_rbb_data, send_data, false).then((res: Response) => {
        resolve(res);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }

  getPrerentCarList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.postFromServer(ApiPath.get_all_active_prerent_car, null, false).then((res: Response) => {
        if (res.result == 'success'){
          this.prerent_car_list$.next(res.data);
          console.log(this.prerent_car_list$.value);
          resolve(true);
        }
        else{
          reject(false);
        }

      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }

  createPrerentOrderCheckoutSession(prerent_order_data, amount: number, name_of_product: string, success_url: string, description: string, images_url: string[], email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let send_data = {
        prerent_order_data: prerent_order_data,
        amount: amount,
        name_of_product: name_of_product,
        success_url: success_url,
        description: description,
        images_url: images_url,
        email: email,
      }
      this.apiService.postFromServer(ApiPath.create_prerent_order_checkout_session, send_data, false).then((res: Response) => {
        console.log(res);
        if (res.result == 'success'){
          resolve(res.data);
        }
        else{
          reject(false);
        }

      }).catch(err => {
        console.log(err);
        reject(err);
      })
    });
  }

  payPrerentOrderDataByCheckoutSession(checkout_session_id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let send_data = {
        checkout_session_id: checkout_session_id,
      }
      this.apiService.postFromServer(ApiPath.pay_prerent_order_data_by_checkout_session, send_data, false).then((res: Response) => {
        console.log(res);
        resolve(res);

      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }
  
  // getBnbVacancyByBnbParkingIdList(bnb_car_parking_id_list: string[]): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     let send_data = {
  //       bnb_car_parking_id_list: bnb_car_parking_id_list
  //     }
  //     console.log(send_data);
  //     this.apiService.postFromServer(ApiPath.get_bnb_vacancy_by_bnb_parking_id_list, send_data).then(res => {
  //       console.log(res.data)
  //       this.bnb_vacancy_list$.next(res.data)

  //       resolve(true);
  //     }).catch(err => {
  //       console.error(err);
  //       reject(err);
  //     })
  //   });
  // }
  
  getCarparkVacancyByParkingIdList(parking_id_list: number[]): Promise<any> {
    return new Promise((resolve, reject) => {
      let send_data = {
        parking_id_list: parking_id_list
      }
      console.log(send_data);
      this.apiService.postFromServer(ApiPath.get_carpark_vacancy_by_parking_id_list, send_data).then(res => {
        console.log(res.data)
        this.carpark_vacancy_list$.next(res.data)

        resolve(true);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }


  getEmergencyDataByVerifyCode(verify_code: string): Promise<Response> {
    return new Promise((resolve, reject) => {
      let send_data = {
        verify_code: verify_code
      }
      this.apiService.postFromServer(ApiPath.get_emergency_data_by_verify_code, send_data).then((res: Response) => {
        if (res.result == 'success') {
          resolve(res);
        }
        else {
          this.commonService.presentSystemErrorToast();
          resolve(null);
        }
      }).catch(err => {
        this.commonService.presentSystemErrorToast();
        console.error(err);
        reject(err);
      })
    });

  }
  
  GeneratePersonalSettlementByEmergencyId(emergency_id, verify_code): Promise<Response> {
    return new Promise((resolve, reject) => {
      let send_data = {
        emergency_id: emergency_id,
        verify_code: verify_code,
        by_pass_verification: false
      }
      this.apiService.postFromServer(ApiPath.generate_personal_settlement_by_emergency_id, send_data, true).then(res => {
        console.log(res);
        resolve(res);
      }).catch(err => {
        console.error(err);
        reject(err);
      })
    });
  }

}