import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'our-influence',
    loadChildren: () => import('./pages/our-influence/our-influence.module').then( m => m.OurInfluencePageModule)
  },
  {
    path: 'plan',
    loadChildren: () => import('./pages/plan/plan.module').then( m => m.PlanPageModule)
  },
  {
    path: 'rental-point',
    loadChildren: () => import('./pages/rental-point/rental-point.module').then( m => m.RentalPointPageModule)
  },
  {
    path: 'press',
    loadChildren: () => import('./pages/press/press.module').then( m => m.PressPageModule)
  },
  {
    path: 'six-plus-one',
    loadChildren: () => import('./pages/six-plus-one/six-plus-one.module').then( m => m.SixPlusOnePageModule)
  },
  {
    path: 'how-to-use',
    loadChildren: () => import('./pages/how-to-use/how-to-use.module').then( m => m.HowToUsePageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'rent-to-buy',
    loadChildren: () => import('./pages/rent-to-buy/rent-to-buy.module').then( m => m.RentToBuyPageModule)
  },
  {
    path: 'cars',
    loadChildren: () => import('./pages/cars/cars.module').then( m => m.CarsPageModule)
  },
  {
    path: 'car-detail',
    loadChildren: () => import('./pages/car-detail/car-detail.module').then( m => m.CarDetailPageModule)
  },
  {
    path: 'rent-with-park',
    loadChildren: () => import('./pages/rent-with-park/rent-with-park.module').then( m => m.RentWithParkPageModule)
  },
  {
    path: 'prerent',
    loadChildren: () => import('./pages/prerent/prerent.module').then( m => m.PrerentPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'prerent-booking',
    loadChildren: () => import('./pages/prerent-booking/prerent-booking.module').then( m => m.PrerentBookingPageModule)
  },
  {
    path: 'prerent-booking-result',
    loadChildren: () => import('./pages/prerent-booking-result/prerent-booking-result.module').then( m => m.PrerentBookingResultPageModule)
  },
  {
    path: 'our-team',
    loadChildren: () => import('./pages/our-team/our-team.module').then( m => m.OurTeamPageModule)
  },
  {
    path: 'opportunities-of-the-underprivileged',
    loadChildren: () => import('./pages/opportunities-of-the-underprivileged/opportunities-of-the-underprivileged.module').then( m => m.OpportunitiesOfTheUnderprivilegedPageModule)
  },
  {
    path: 'long-term-rental',
    loadChildren: () => import('./pages/long-term-rental/long-term-rental.module').then( m => m.LongTermRentalPageModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'tesla',
    loadChildren: () => import('./pages/tesla/tesla.module').then( m => m.TeslaPageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog/blog.module').then( m => m.BlogPageModule)
  },
  {
    path: 'article',
    loadChildren: () => import('./pages/article/article.module').then( m => m.ArticlePageModule)
  },
  {
    path: 'enterprise-solution',
    loadChildren: () => import('./pages/enterprise-solution/enterprise-solution.module').then( m => m.EnterpriseSolutionPageModule)
  },
  {
    path: 'settlement-verification',
    loadChildren: () => import('./pages/settlement-verification/settlement-verification.module').then( m => m.SettlementVerificationPageModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
