// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const isProduction = false;

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: isProduction,
  force_dark_mode: true,
  enable_api_log: isProduction ? false : true,
  enable_console_log: isProduction ? true : true,
  google_api_key: "AIzaSyCGw1FvrlUmQS_J_80OoXqfTZVT1V-wUF8",
  stripe_publishable_key: !isProduction ? 'pk_test_3CW9ePepWe2N91ZUvuuxNdJ80072HvLWry' : 'pk_live_ZponeqWdCYyuGhZp2nUDa4Ye00Wlax2B59',
  apply_pay_merchant_identifier: 'merchant.com.wasphk.client',
  root_url: !isProduction ? 'https://dev.goswap.com.hk/' : 'https://goswap.com.hk/',
  api_url: !isProduction ? 'https://dev.wasphk-rental.com/backend/request.php' : 'https://prod.wasphk-rental.com/backend/request.php',
  media_url: !isProduction ? 'https://dev.wasphk-rental.com/media/' : 'https://prod.wasphk-rental.com/media/',
  seven_url: !isProduction ? 'https://dev.wasphk-rental.com/seven_images/' : 'https://prod.wasphk-rental.com/seven_images/',
  asset_url: !isProduction ? 'https://dev.wasphk-rental.com/assets/' : 'https://prod.wasphk-rental.com/assets/',
  oil_or_electricity_level_threshold: !isProduction ? 50 : 50
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
