import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
// import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { AnalyticsService } from './analytics.service';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public current_router_url: string = '';
  public preivous_router_url: string = '';

  constructor(
    private nav: NavController,
    private router: Router,
    private analyticsService: AnalyticsService
  ) { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        // console.log(event);
        this.preivous_router_url = this.router.url.split('?')[0];
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        // console.log(event);
        this.current_router_url = this.router.url.split('?')[0] ;
        // console.log(this.current_router_url);
        // console.log("current url: ",this.current_router_url);
        this.analyticsService.trackPage(this.router.url);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        // console.log(event);
        // Present error to user
        // console.log(event.error);
        // this.current_router_url.includes('123');
      }
    });
  }

  navigate(direction: 'root' | 'forward' | 'back' | 'pop' | 'blank', path: string, options?: NavigationOptions){
    if (options == undefined){
      options = {
        // queryParams: {
  
        // }
        animated: false
      }
    }
    switch (direction) {
      case 'root':
        this.nav.navigateRoot(path, options);
        break;
      case 'forward':
        this.nav.navigateForward(path, options);
        break;
      case 'back':
        this.nav.navigateBack(path, options);
        break;
      case 'pop':
        this.nav.pop();
        break;
      case 'blank':
        window.open(path, '_blank');
        break;
    
      default:
        break;
    }


  }


}
